import { store } from '../../../store.js';
import { LIGHTBOX_ACTION } from '../../Generic/actions/types';
import { openPdfFIle } from '../../../helpers/functions/functions';
import MESSAGES from '../../../helpers/messages';

export const fetchLinkTypeAction = (params, callback = false) => (dispatch) => {
	const state = store.getState();

	let url = state.config.apihost;

	switch (params.link_type) {
		case 'files':
			url = url + params.link;
			if (params.ref_type == MESSAGES.REF.TICKETS_FILES){
				if (callback) {
					callback(params.ref_id, MESSAGES.REF.TICKETS_FILES);
				}
			}
			break;
		default:
			break;
	}

	fetch(url)
		.then((resp) => resp.blob())
		.then((blob) => {
			const fileURL = URL.createObjectURL(blob);
			if (blob.type.split('/')[0] === 'image') {
				dispatch({
					type: LIGHTBOX_ACTION,
					payload: {
						open: true,
						image: [
							{
								src: fileURL,
								thumbnail: fileURL,
								thumbnailWidth: 0,
								thumbnailHeight: 0,
							},
						],
					},
				});
			} else {
				openPdfFIle(fileURL);
			}
		})
		.catch(() => alert('oh no!'));
};
