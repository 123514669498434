import axios from 'axios';
import { store } from '../store.js';
import { FETCH_SETTINGS, ADD_PARAMS_SETTINGS, TRANSLATIONS_LABEL_TAG, REMOVE_PARAMS_SETTING } from '../actions/types';
import { handleSplashScreenAction } from '../components/Generic/actions/genericActions';
import { addTranslationForLanguage } from 'react-localize-redux';
import { setActiveLanguage } from 'react-localize-redux';
import { fetchTranslationsAction } from './translationsActions';
import { ConsoleView } from 'react-device-detect';
import { apiRequest } from '../helpers/functions/request.js';

export const fetchSettingsAction = () => (dispatch) => {
	const state = store.getState();

	dispatch(handleSplashScreenAction(true));

	axios
		.get(state.config.apihost + `/configuration`)
		.then((res) => {
			if (res.headers.hasOwnProperty('x-version-hash')) {
				if (process.env.REACT_APP_ACVERSION !== res.headers['x-version-hash']) {
					window.location.reload(true);
				}
			}

			dispatch({
				type: FETCH_SETTINGS,
				payload: res.data,
			});

			return res.data;
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const handleTranslationsSettingsAction = () => async (dispatch) => {
	const state = store.getState();

	Object.keys(state.settings.translations).forEach((key) => {
		dispatch(addTranslationForLanguage(state.settings.translations[key], key));
	});
	var codes = state.settings.site.available_languages.reduce((array, object) => array.concat(object.code), []);

	var language =
		state.settings.params !== null && state.settings.params.language && codes.includes(state.settings.params.language)
			? state.settings.params.language
			: state.settings.site.default_language;

	dispatch(setActiveLanguage(language));
	document.getElementsByTagName('html')[0].setAttribute('lang', language.split('-')[0]);

};

export const addSettingsAction = (params) => (dispatch) => {
	dispatch({
		type: ADD_PARAMS_SETTINGS,
		payload: params,
	});
};

export const removeSettingsAction = (params) => (dispatch) => {
	console.log('8 - remove settings');
	dispatch({
		type: REMOVE_PARAMS_SETTING,
		payload: params,
	});
};

export const showTranslationsLabelAction = () => (dispatch) => {
	const state = store.getState();
	dispatch({
		type: TRANSLATIONS_LABEL_TAG,
		payload: !state.settings.show_label_tag,
	});
};

export const handleToggleTranslations = () => (dispatch) => {
	const state = store.getState();

	dispatch(showTranslationsLabelAction());

	if (state.settings.show_label_tag) {
		dispatch(setActiveLanguage('raw'));
	} else {
		dispatch(handleTranslationsSettingsAction());
		dispatch(fetchTranslationsAction(state.users.whoami.language ? state.users.whoami.language : state.settings.site.default_language));
	}
};

export const loadConfigurations = () => async (dispatch) => {
	const state = store.getState();
	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/configuration`,
		...(state.login.login && { alert: 'alert_login' }),
	});

	if (response.headers.hasOwnProperty('x-version-hash')) {
		if (process.env.REACT_APP_ACVERSION !== response.headers['x-version-hash']) {
			window.location.reload(true);
		}
	}

	if (typeof response.data.site !== 'undefined' && response.data.site.files.favicon) {
		const favElement = document.getElementById('favicon');

		favElement.href = state.config.apihost + '/configuration/files/' + response.data.site.files.favicon.uuid;
	}

	if (typeof response.data.site !== 'undefined' && response.data.site.page_title) {
		document.title = response.data.site.page_title;
	}

	dispatch({
		type: FETCH_SETTINGS,
		payload: response.data,
	});

	return response.data;
};