import axios from 'axios';
import {
	FETCH_QUESTIONNAIRES,
	FETCH_QUESTIONNAIRE,
	PATCH_QUESTIONNAIRE,
	TRIGGER_QUESTION,
	ANSWER_QUESTION,
	PROGRESS_QUESTIONNAIRE,
	TRIGGER_CHILDREN,
	HANDLE_QUESTIONNAIRE_ERROR,
} from './types';
import { store } from '../../../store.js';
import { handleLoadingAction, displayModalAction } from '../../Generic/actions/genericActions';
import { updateTicketAction, postPayment } from '../../Tickets/actions/ticketsActions';
import { setPromptDialogIsDirty } from '../../Generic/actions/promptActions';
import eventsService from '../../../helpers/Events';
import { getTranslate } from 'react-localize-redux';
import { bytesToMegaBytes } from './../../../helpers/functions/functions';
import { push } from 'connected-react-router';

export const fetchQuestionnairesAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/questionnaires`, {
			params: { language: state.users.whoami.language, active: 'true' },
		})
		.then((res) => {
			dispatch({
				type: FETCH_QUESTIONNAIRES,
				payload: res.data,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const cleanQuestionnairesAction = () => (dispatch) => {
	dispatch({
		type: FETCH_QUESTIONNAIRES,
		payload: '',
	});
};

export const fetchQuestionnaireAction =
	(params, loading = true) => (dispatch) => {
		if (loading) {
			dispatch(handleLoadingAction(true));
		}

		const state = store.getState();
		let questionnaire_id = params.questionnaire_id;
		
		axios
			.get(state.config.apihost + `/questionnaires/` + questionnaire_id)
			.then((res) => {
				/*
                Adding answer/triggered parameter to all questions object
            */
				res.data.children.forEach((question) => {
					question.answer = '';
					if (question.type === 'text') {
						question.answer = question.text;
					}
					question.triggered = question.parent_id === '0' ? true : false;
					question.has_errors = false;
				});

				dispatch({
					type: FETCH_QUESTIONNAIRE,
					payload: {
						questionnaire: res.data,
						layout: res.data.layout,
					},
				});

				if (loading) {
					dispatch(handleLoadingAction(false));
				}
			})
			.catch((error) => {
				console.log(error);
				if (loading) {
					dispatch(handleLoadingAction(false));
				}
			});
	};

export const handleQuestionnaireErrors = (props) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let question_children = [];
	let questionnaire_error = false;
	let text_error = false;

	state.questionnaires.questionnaire.children.forEach((question, i) => {
		if (
			question.triggered === true &&
			question.mandatory === '1' &&
			((question.type == 'uploadImage' && question.answer === '') || (question.type !== 'uploadImage' && question.answer === ''))
		) {
			state.questionnaires.questionnaire.children[i].has_errors = true;
			state.questionnaires.questionnaire.children[i].questionnaire_text_error = translate('ticket_questionnaire_question_required');
			question_children[i] = state.questionnaires.questionnaire.children[i];
			questionnaire_error = true;
		} else if (
			question.triggered === true &&
			['numberInput', 'rangeInput'].includes(question.type) &&
			((question.options[0].start && Number(question.answer) < Number(question.options[0].start)) ||
				(question.options[0].end && Number(question.answer) > Number(question.options[0].end)))
		) {
			state.questionnaires.questionnaire.children[i].has_errors = true;
			state.questionnaires.questionnaire.children[i].questionnaire_text_error = translate('ticket_questionnaire_invalid_value', {
				min: question.options[0].start,
				max: question.options[0].end,
			});
			question_children[i] = state.questionnaires.questionnaire.children[i];
			questionnaire_error = true;
		} else if (question.triggered === true && ['uploadImage'].includes(question.type) && question.answer && question.answer.length > 0) {
			let invalid = [];
			question.answer.forEach((image, index) => {
				if (bytesToMegaBytes(image.size) >= state.settings.files.max_upload_size.replace('M', ' ')) {
					state.questionnaires.questionnaire.children[i].has_errors = true;
					state.questionnaires.questionnaire.children[i].questionnaire_text_error = translate('form_field_bigger_maxsize');
					question_children[i] = state.questionnaires.questionnaire.children[i];
					questionnaire_error = true;
					invalid.push(index);
				} else if (image.type === '' || image.type === 'application/octet-stream') {
					state.questionnaires.questionnaire.children[i].has_errors = true;
					state.questionnaires.questionnaire.children[i].questionnaire_text_error = translate('form_field_mime_type_invalid');
					question_children[i] = state.questionnaires.questionnaire.children[i];
					questionnaire_error = true;
					invalid.push(index);
				}
			});

			if (invalid.length === 0) {
				state.questionnaires.questionnaire.children[i].has_errors = false;
				question_children[i] = state.questionnaires.questionnaire.children[i];
			}
		} else {
			state.questionnaires.questionnaire.children[i].has_errors = false;
			question_children[i] = state.questionnaires.questionnaire.children[i];
		}
	});

	state.questionnaires.questionnaire.children = question_children;

	dispatch({
		type: HANDLE_QUESTIONNAIRE_ERROR,
		payload: {
			questionnaire: state.questionnaires.questionnaire,
			questionnaire_has_errors: questionnaire_error,
			questionnaire_text_error: text_error,
		},
	});

	if (questionnaire_error === false) {
		props.followup ? dispatch(patchFollowupQuestionnaireAction(props.questionnaire_id)) : dispatch(patchQuestionnaireAction());
	}
};

export const patchQuestionnaireAction = () => (dispatch) => {
	dispatch(handleLoadingAction(true));
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let question_answered = state.questionnaires.questionnaire.children.filter((question) => question.triggered === true);

	let answers = [];
	let qObj;
	const form_data = new FormData();
	let filesArray = [];

	question_answered.forEach((question, i) => {
		if (question.type !== 'uploadImage') {
			qObj = {
				id: question.id,
				value: question.answer.toString(),
				points: question.points,
			};
			answers.push(JSON.stringify(qObj));
		} else {
			if (question.answer.length > 0) {
				question.answer.forEach((file, i) => {
					if (file instanceof File) {
						file.question = question.id;
						filesArray.push(file);
					}
				});
			}
		}
	});

	if (filesArray.length === 1) {
		form_data.append('q-' + filesArray[0].question, filesArray[0], filesArray[0].name);
	} else if (filesArray.length > 1) {
		filesArray.forEach(function (file) {
			form_data.append('q-' + file.question + '[]', file, file.name);
		});
	}

	form_data.append('answers', '[' + answers + ']');

	axios
		.patch(
			state.config.apihost +
				`/tickets/` +
				state.tickets.ticket.id +
				`/questionnaires/` +
				state.tickets.questionnaires.next_ticket_questionnaire,
			form_data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)
		.then((res) => {
			dispatch({
				type: PATCH_QUESTIONNAIRE,
				payload: {
					current_ticket_questionnaire: res.data.id,
					next_questionnaire: res.data.next_questionnaire,
					next_ticket_questionnaire: res.data.next_ticket_questionnaire,
					triage_result: res.data.triage_result,
					completed: true,
				},
			});

			dispatch(handleLoadingAction(false));

			if (res.data.triage_result !== null) {
				dispatch(
					setPromptDialogIsDirty({
						is_dirty: false,
						back_label: 'questionnaires_prompt_back_btn_label',
						next_label: 'questionnaires_prompt_next_btn_label',
					})
				);
				if (window.self !== window.top) {
					window.parent.postMessage(
						JSON.stringify({
							message: translate('alert_post_questionnaire_triage_failed'),
							state: 'failed',
						}),
						'*'
					);
				} else {
					store.dispatch(push('/case/new/triage/failed'));
					eventsService.triggerEvent('alert', { type: 'warning', message: translate('alert_post_questionnaire_triage_failed') });
				}
			} else {
				if (typeof res.data.next_ticket_questionnaire !== 'undefined' && res.data.next_ticket_questionnaire !== null) {
					// next questionnaire
					dispatch(fetchQuestionnaireAction({ questionnaire_id: res.data.next_questionnaire }));
					if (window.self !== window.top) {
						window.parent.postMessage(
							JSON.stringify({
								message: translate('alert_post_questionnaire_save'),
								state: 'next_questionnaire',
							}),
							'*'
						);

						store.dispatch(push('/case/new/plain/' + res.data.tickets_id + '/questionnaires/' + res.data.next_ticket_questionnaire));
					} else {
						store.dispatch(push('/case/new/' + res.data.tickets_id + '/questionnaires/' + res.data.next_ticket_questionnaire));
					}
				} else if (window.self !== window.top) {
					window.parent.postMessage(
						JSON.stringify({
							message: translate('alert_post_questionnaire_flow_finished'),
							state: 'completed',
						}),
						'*'
					);
				} else if (res.data.tickets_id === state.tickets.ticket.id && state.tickets.ticket.condition.price == '0') {
					dispatch(postPayment(state.tickets.ticket.id, 'consent', true));
				} else {
					store.dispatch(push('/case/new/' + res.data.tickets_id + '/payment'));
				}

				if (window.self === window.top) {
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_questionnaire_save') });
				}
			}
		})
		.catch((err) => {
			dispatch(handleLoadingAction(false));
			if (window.self !== window.top) {
				window.parent.postMessage(
					JSON.stringify({
						message: translate('alert_post_questionnaire_failed'),
						state: 'error',
					}),
					'*'
				);
			} else {
				eventsService.triggerEvent('alert', { type: 'error', message: translate('alert_post_questionnaire_failed') });
			}
		});
};

export const triggerQuestionAction =
	(type, followup_questionnaire_id = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		let next = state.questionnaires.next;
		let previous = state.questionnaires.previous;
		let index = state.questionnaires.question_index;

		let total_questions = state.questionnaires.questionnaire.children.filter((child) => child.trigger).length - 1;

		if (state.questionnaires.questionnaire.children[index] !== undefined) {
			dispatch(handleLoadingAction(true));
		}

		switch (type) {
			case 'next':
				index++;
				while (
					state.questionnaires.questionnaire.children[index] !== undefined &&
					!state.questionnaires.questionnaire.children[index].triggered
				) {
					index++;
				}

				previous = true;

				break;
			case 'back':
				index--;

				while (
					state.questionnaires.questionnaire.children[index] !== undefined &&
					!state.questionnaires.questionnaire.children[index].triggered
				) {
					index--;
				}

				next = true;

				break;
			default:
				break;
		}

		previous = index === 0 ? false : previous;

		next = index === total_questions ? false : next;

		if (state.questionnaires.questionnaire.children[index] !== undefined) {
			dispatch(progressWizardAction(index));

			dispatch({
				type: TRIGGER_QUESTION,
				payload: {
					question_index: index,
					next,
					previous,
				},
			});

			dispatch(handleLoadingAction(false));
		} else {
			dispatch(handleLoadingAction(false));
			dispatch(
				displayModalAction({
					title: translate('modal_title_finish_questionnaire'),
					description: translate('modal_description_finish_questionnaire'),
					button_no: translate('modal_button_no_finish_questionnaire'),
					button_yes: translate('modal_button_yes_finish_questionnaire'),
					action: (props) =>
						followup_questionnaire_id === false
							? dispatch(patchQuestionnaireAction(props))
							: dispatch(patchFollowupQuestionnaireAction(followup_questionnaire_id)),
					props: null,
				})
			);
		}
	};

export const answerQuestionAction = (value, question_index, points) => (dispatch) => {
	const state = store.getState();

	let question = state.questionnaires.questionnaire.children[question_index];

	question.answer = value;
	question.points = parseInt(points);

	dispatch(triggerChildrenAction(question_index));

	if (!state.questionnaires.wizard) {
		dispatch(progressFlatAction());
	}

	dispatch({
		type: ANSWER_QUESTION,
		payload: state.questionnaires.questionnaire,
	});
};

export const multipleAnswerQuestionAction = (value, question_index, points, isChecked) => (dispatch) => {
	const state = store.getState();

	let question = state.questionnaires.questionnaire.children[question_index];

	let values = !!question.answer ? question.answer : [];

	let checked = isChecked ? true : false;

	let total_points = 0;
	if (checked) {
		values.push(value);
		total_points = parseInt(question.points) + parseInt(points);
	} else {
		let index = values.indexOf(value);
		if (index > -1) {
			values.splice(index, 1);
		}
		total_points = parseInt(question.points) - parseInt(points);
	}

	question.answer = !values.length ? '' : values;
	question.points = total_points;

	dispatch(triggerChildrenAction(question_index));

	if (!state.questionnaires.wizard) {
		dispatch(progressFlatAction());
	}

	dispatch({
		type: ANSWER_QUESTION,
		payload: state.questionnaires.questionnaire,
	});
};

export const imageUploadAnswerQuestionAction = (files, points, index) => (dispatch) => {
	const state = store.getState();

	let question = state.questionnaires.questionnaire.children[index];

	if (files.length !== 0) {
		if (question.answer.length === 0) {
			question.answer = [];
			question.answer.push(files[0]);
		} else if (Array.isArray(question.answer)) {
			question.answer = files;
		}
		question.points = parseInt(points);
	} else {
		question.answer = '';
		question.points = 0;
	}

	dispatch(triggerChildrenAction(index));

	if (!state.questionnaires.wizard) {
		dispatch(progressFlatAction());
	}

	dispatch({
		type: ANSWER_QUESTION,
		payload: state.questionnaires.questionnaire,
	});
};

export const progressWizardAction = (index) => (dispatch) => {
	const state = store.getState();

	let progress = state.questionnaires.progress;

	let answered = 0;
	for (let i = index; i > 0; i--) {
		if (state.questionnaires.questionnaire.children[i].triggered) {
			answered++;
		}
	}
	let question_to_answer = state.questionnaires.questionnaire.children.filter((question) => question.triggered === true).length;

	progress = Math.round((100 / question_to_answer) * answered);

	dispatch({
		type: PROGRESS_QUESTIONNAIRE,
		payload: progress,
	});
};

export const progressFlatAction = () => (dispatch) => {
	const state = store.getState();

	let progress = state.questionnaires.progress;

	let answered = state.questionnaires.questionnaire.children.filter(
		(question) => question.triggered === true && question.answer !== '' && question.type !== 'text'
	).length;

	let question_to_answer = state.questionnaires.questionnaire.children.filter(
		(question) => question.triggered === true && question.type !== 'text'
	).length;

	progress = Math.round((100 / question_to_answer) * answered);

	dispatch({
		type: PROGRESS_QUESTIONNAIRE,
		payload: progress,
	});
};

// TODO: Implement validations to avoid errors if the questionnaire structure is not right.

export const triggerChildrenAction = (index) => (dispatch) => {
	const state = store.getState();
	if (state.questionnaires.questionnaire.children[index].triggers) {
		state.questionnaires.questionnaire.children[index].triggers.forEach((trigger, i) => {
			let trigger_question = state.questionnaires.questionnaire.children.filter((question) => question.id == trigger.triggers);

			if (typeof trigger_question[0] !== 'undefined') {
				trigger_question[0].triggered = false;
				switch (state.questionnaires.questionnaire.children[index].type) {
					case 'singleChoice':
						if (state.questionnaires.questionnaire.children[index].points == trigger.points) {
							trigger_question[0].triggered = true;
						}
						break;

					default:
						if (state.questionnaires.questionnaire.children[index].points >= trigger.points) {
							trigger_question[0].triggered = true;
						}
				}
			}
		});
	}

	dispatch({
		type: TRIGGER_CHILDREN,
		payload: state.questionnaires.questionnaire,
	});
};

export const patchFollowupQuestionnaireAction = (questionnaire_id) => (dispatch) => {
	dispatch(handleLoadingAction(true));
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let question_answered = state.questionnaires.questionnaire.children.filter((question) => question.triggered === true);

	let answers = [];
	let qObj;
	const form_data = new FormData();
	let filesArray = [];

	question_answered.forEach((question, i) => {
		if (question.type !== 'uploadImage') {
			qObj = {
				id: question.id,
				value: question.answer.toString(),
				points: question.points,
			};
			answers.push(JSON.stringify(qObj));
		} else if (question.answer.length > 0) {
			question.answer.forEach((file, i) => {
				if (file instanceof File) {
					file.question = question.id;
					filesArray.push(file);
				}
			});
		}
	});

	if (filesArray.length === 1) {
		form_data.append('q-' + filesArray[0].question, filesArray[0], filesArray[0].name);
	} else if (filesArray.length > 1) {
		filesArray.forEach(function (file) {
			form_data.append('q-' + file.question + '[]', file, file.name);
		});
	}

	form_data.append('answers', '[' + answers + ']');

	form_data.append('statusupdate', '0');
	//TODO: update ticket state when blocking questionnaires are answered
	// form_data.append('statusupdate', '1');

	axios
		.patch(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/questionnaires/` + questionnaire_id, form_data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			dispatch(handleLoadingAction(false));

			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			store.dispatch(push('/case'));
			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_questionnaire_save') });
		})
		.catch((err) => {
			dispatch(handleLoadingAction(false));
			eventsService.triggerEvent('alert', { type: 'error', message: translate('alert_post_questionnaire_failed') });
		});
};
